@import '../../styles/variables';

.bids {
	&__categories {
		flex-direction: row;
		font-size: 14px;
		line-height: 16px;
		padding: 0.5em 0.125em;

		&-item {
			&:not(:first-of-type) {
				margin-left: 20px;
			}
		}

		.form__checkbox-mask {
			background-size: 14px 10px;
			border-width: 3px;
			height: 20px;
			width: 20px;
		}
	}

	&__table {
		margin-top: 10px;

		[data-accessor='createdAt'] {
			max-width: 120px;
		}

		[data-accessor='period'] {
			max-width: 200px;
		}

		[data-accessor='playlistChange'] {
			max-width: 230px;
		}
	}

	&__monitor {
		background-image: url('../../assets/icons/monitor-off.svg');
		background-position: left center;
		background-repeat: no-repeat;
		background-size: contain;
		display: inline;
		line-height: 24px;
		padding-left: 27px;
		vertical-align: middle;
	}

	&__playlist {
		background-image: url('../../assets/icons/playlist-off.svg');
		background-position: 6px center;
		background-repeat: no-repeat;
		background-size: 21px;
		border: solid 1px currentColor;
		border-radius: 22px;
		box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);
		display: inline;
		font-weight: 400;
		line-height: 24px;
		padding: 1px 10px 0 31px;
		vertical-align: middle;

		&:hover {
			box-shadow: 0 1px 7px 1px rgba(0, 0, 0, 0.2);
		}
	}

	&__status-ok {
		color: use-color('green');
	}

	&__confirm {
		background-image: url('../../assets/icons/playlist-confirm.svg');
		height: 20px;
		margin-right: 23px;
		width: 20px;
	}

	&__deny {
		background-image: url('../../assets/icons/playlist-cancel.svg');
		height: 21px;
		width: 21px;
	}

	&__window {
		@include backdrop-filter(blur(3px));
		align-items: center;
		background-color: rgba(71, 79, 100, 0.9);
		bottom: 0;
		display: flex;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2000;
	}

	&__player-layout {
		position: relative;
		width: 996px;
	}

	&__player {
		background-color: use-color('dark');
		border-radius: 10px;
		height: 560px;
	}

	&__close {
		background-image: url('../../assets/icons/close-button-hollow.svg');
		height: 36px;
		position: absolute;
		right: -56px;
		top: 0;
		transition: $main-transition;
		width: 36px;

		&:hover,
		&:active {
			background-image: url('../../assets/icons/close-button-hollow-hover.svg');
		}
	}

	&__controls-layout {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
	}

	&__info {
		align-items: center;
		background-color: use-color('dark');
		border-radius: 5px;
		color: use-color('white');
		display: flex;
		font-size: 12px;
		height: 50px;
		justify-content: space-between;
		line-height: 14px;
		margin-top: 11px;
		padding: 10px;
		width: 350px;
	}

	&__info-name {
		background-image: url('../../assets/icons/monitor-white.svg');
		background-position: left center;
		background-repeat: no-repeat;
		background-size: 20px 18px;
		display: inline-block;
		height: 18px;
		padding: 2px 0 0 34px;
	}

	&__info-container {
		display: flex;
	}

	&__info-time,
	&__info-price {
		align-items: center;
		background-color: use-color('gray');
		border-radius: 5px;
		display: flex;
		height: 30px;
		justify-content: center;
		width: 60px;
	}

	&__info-price {
		margin-left: 10px;
	}

	&__controls {
		align-items: center;
		display: flex;
		justify-content: space-between;
		width: 240px;
	}

	&__controls-button {
		background-color: use-color('dark');
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 50%;
		color: use-color('white');
		font-size: 12px;
		line-height: 14px;
		transition: background-color 0.2s;

		&:active {
			background-color: #3d4559;
		}
	}

	&__decor {
		background-color: use-color('dark');
		border-radius: 50%;
		height: 20px;
		width: 20px;
	}

	&__speed {
		height: 20px;
		width: 20px;
	}

	&__rewind {
		background-image: url('../../assets/icons/rewind.svg');
		height: 40px;
		width: 40px;
	}

	&__forward {
		background-image: url('../../assets/icons/forward.svg');
		height: 40px;
		width: 40px;
	}

	&__play {
		background-image: url('../../assets/icons/play.svg');
		background-position: 32px center;
		height: 80px;
		width: 80px;

		&--on {
			background-image: url('../../assets/icons/pause.svg');
			background-position: center;
			background-size: 22px;
		}
	}

	&__buttons {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		[disabled] {
			background-color: use-color('dark');
			cursor: not-allowed;
			opacity: 0.7;
		}
	}

	&__buttons-approve,
	&__buttons-deny {
		align-items: center;
		background-color: use-color('green');
		border-radius: 5px;
		color: use-color('white');
		display: flex;
		font-size: 18px;
		height: 50px;
		justify-content: center;
		line-height: 21px;
		transition: $main-transition;
		width: 171px;
	}

	&__buttons-approve {
		background-color: use-color('green');
	}

	&__buttons-deny {
		background-color: use-color('dark');
		margin-left: 6px;
	}

	button.bids__buttons {
		&-approve {
			&:active {
				background-color: use-color('dark-green');
			}
		}

		&-deny {
			&:active {
				background-color: #3d4559;
			}
		}
	}
}

.bid-form {
	background-color: #f0f0f0;
	border-radius: 3px;
	padding: 0.9em 0;
	text-align: center;

	h3 {
		font-size: 17px;
		margin: 0.5em 0;
	}

	section {
		&:not(&:first-of-type) {
			border-top: dashed 1px use-color(dark);
			padding-top: 12px;
		}
	}

	fieldset {
		align-items: center;
		display: flex;
		justify-content: center;
		padding: 1.5em;

		&[name='queue'] {
			.bid-form__field:not(&:first-child) {
				margin-left: 7px;
			}
		}

		&[name='calendar'] {
			padding: 0;
		}
	}

	&__field {
		align-items: center;
		display: flex;
		font-size: 12px;
		min-width: 90px;

		.form__field {
			margin-right: 7px;
		}

		&[data-name='onlyWhen'],
		&[data-name='both'] {
			border: solid 2px;
			border-radius: 2px;
			padding: 2px 4px;

			&::after {
				background-image: url('../../assets/icons/filter-date-dark.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: 60%;
				content: '';
				height: 23px;
				margin-left: 7px;
				width: 25px;
			}
		}
	}

	&-popup {
		.popup-fullscreen__container {
			min-height: 560px;
			width: unset;
		}
	}

	.button-primary {
		margin: 0 auto;
		padding: 0 0.5em;
		width: unset;
	}
}
