@import '../../styles/variables';

.video {
	user-select: none;

	&__output {
		height: calc(100% - 30px);
		object-fit: contain;
		width: 100%;
	}
}
