@import '../../styles/variables';

.preloader {
	&__wrapper {
		align-items: center;
		background-color: use-color('light-dim');
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		overflow: hidden;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 9999;
	}

	&-transition {
		&-enter {
			opacity: 0;
			transform: scale(1.5);
			transition: all 0.3s ease-out;
		}

		&-enter-active {
			opacity: 1;
			transform: scale(1);
		}

		&-exit {
			opacity: 1;
			transform: scale(1);
		}

		&-exit-active {
			opacity: 0;
			transform: scale(1.5);
			transition: all 0.3s ease-in;
		}
	}
}
