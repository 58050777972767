@import '../../styles/variables';

.big-input__form {
	align-items: center;
	flex-direction: row;
	font-size: 1.25em;
	padding: 0;
	padding-bottom: 20px;
	position: relative;

	> .form__field {
		margin: 0;
	}

	&_hint {
		font-size: 18px;
	}

	&__input {
		background-color: use-color('white');
		border: solid 2px use-color('green');
		border-radius: 10px;
		color: use-color('dark');
		font-size: 2em;
		height: 60px;
		text-align: center;
		text-indent: 1em;
		width: 400px;

		&:focus,
		&:active {
			border: solid 2px use-color('green');
		}

		@include tablet {
			font-size: 1.5em;
		}

		@include mobile {
			font-size: 1.5em;
		}

		&-icon {
			height: 40px;
			left: 10px;
			position: absolute;
			top: 10px;

			@include tablet {
				height: 2.5em;
				left: 0.25em;
				top: 0.25em;
			}
			@include mobile {
				height: 2.5em;
				left: 0.25em;
				top: 0.25em;
			}
		}
	}

	&__submit-btn {
		background-color: use-color('green');
		border-radius: 0.5em;
		height: 60px;
		margin: 0 20px;
		padding: 0.75em;
		width: 60px;

		@include tablet {
			flex-basis: 8%;
			padding: 0.5em;
		}
		@include mobile {
			flex-basis: 8%;
			padding: 0.5em;
		}

		&-icon {
			height: 1.5em;

			&.rot-90 {
				transform: rotate(-90deg);
			}

			@include mobile {
				height: 1em;
			}
		}
	}
}

.big-input {
	&__playlist-submit {
		height: 80px;
		width: 80px;
	}

	&__playlist-input {
		font-size: 0.9em;

		&::placeholder {
			color: use-color('green');
		}
	}
}
