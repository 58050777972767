@import '../../styles/variables';

.monitor {
	--filter-height: 50px;
	--map-height: calc(var(--filter-height) * 1.8);
	--filter-width: 740px;

	&__add-edit-form {
		display: flex;
		flex-direction: column;
		max-width: 800px;

		@media screen and (min-width: 1500px) {
			flex-direction: row;
			justify-content: space-between;
			max-width: none;
		}

		.add__edit__field {
			margin-bottom: 30px;
		}
	}

	&__form-column {
		@media screen and (min-width: 1500px) {
			width: 45%;
		}
	}

	&__file-label {
		flex: 0;
	}

	&__input-info {
		background-position-x: 240px;
		width: 270px !important;

		&--angle,
		&--brightness {
			width: max(100%, 100px) !important;
		}
	}

	&__monitor {
		&__layout {
			background-color: use-color('light');
			border-radius: 3px;
			padding: 2em;
		}

		&__field {
			flex-basis: 40%;

			&-label {
				font-size: 14px;
				position: absolute;
				top: -1.25em;
			}

			&-model {
				label {
					padding: 1em !important;
				}
			}

			&__wrapper {
				margin-top: 40px;

				@include mobile {
					flex-flow: column;
				}
			}

			&__wrapper > & {
				&:not(:last-child) {
					margin-bottom: 0;

					@include mobile {
						margin-bottom: 1em;
						margin-right: 0;
					}
				}
			}
		}
	}

	&__field-coordinates {
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			appearance: none;
			margin: 0;
		}

		input[type='number'] {
			appearance: textfield;
		}
	}

	&__toggle {
		align-items: center;
		background-color: use-color('dark');
		border-radius: 5px;
		bottom: 33px;
		display: inline-flex;
		height: 24px;
		justify-content: center;
		left: 25px;
		position: absolute;
		width: 24px;

		.icon {
			--size: 12px;
		}
	}

	&__info {
		background-color: use-color('dark');
		border-radius: 10px;
		bottom: 10px;
		color: use-color('light-dim');
		cursor: pointer;
		display: inline-block;
		font-size: 13px;
		font-weight: 400;
		left: 50%;
		opacity: 0.9;
		padding: 5px 10px;
		position: absolute;
		transform: translateX(-50%);

		&.empty {
			background-color: use-color('red');
			opacity: 0.8;
		}
	}

	&__map {
		height: var(--map-height);
		overflow: hidden;
		position: sticky;
		top: calc(var(--header-height) + var(--demobar-height));
		transition: height 0.2s;
	}

	&__placemark {
		--size: 40px;

		&-label {
			align-items: center;
			display: inline-flex;
			flex-direction: column;
			row-gap: 3px;

			.icon {
				--size: 18px;
			}

			span {
				font-size: 10px;
			}

			&.with-count {
				padding-top: 20%;
			}
		}

		&-popup {
			&-top,
			&-bottom {
				padding: 8px 12px;
			}

			&-top,
			&-bottom {
				align-items: center;
				background-color: white;
				column-gap: 5px;
				display: flex;
				font-size: 13px;
				position: sticky;
			}

			&-bottom {
				bottom: 0;
				justify-content: flex-end;
			}

			&-top {
				justify-content: space-between;
				top: 0;

				.monitor__filter-category {
					--button-size: 33px;
					--icon-size: 20px;

					&-button {
						background-color: use-color('dark');
						border-color: use-color('dark');
						pointer-events: none;
					}
				}
			}

			&-button {
				align-items: center;
				column-gap: 5px;
				display: flex;
			}

			&-table {
				font-size: 12px;
				overflow-y: auto;
				padding-left: 0;

				table {
					border-collapse: separate;
					border-spacing: 10px 5px;
				}

				th,
				td {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

					.table__icon-name {
						height: 16px;
					}
				}
			}

			&-info {
				font-size: 12px;

				table {
					border-collapse: separate;
					border-spacing: 0 5px;
				}

				td {
					padding: 8px 0;
					--padding-x: 10px;

					&:first-child {
						background-color: use-color('gray');
						color: use-color('light');
						font-weight: 400;
						padding-right: var(--padding-x);
						text-align: right;
					}

					&:last-child {
						padding-left: var(--padding-x);
					}
				}
			}
		}

		.placemark {
			&__icon {
				cursor: pointer;
			}
		}

		&.cluster {
			--size: 30px;

			.placemark {
				&__icon {
					border-radius: var(--size);
				}
			}
		}

		&.cluster & {
			&-label {
				padding-top: 0;

				span {
					font-size: 17px;
				}
			}
		}
	}

	&.map-expand {
		--map-height: calc(100vh - (var(--header-height) + var(--demobar-height)));
	}

	&.map-expand & {
		&__filter {
			&-steps,
			&-form {
				box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
			}
		}

		&__toggle {
			bottom: 10px;
		}
	}

	&:not(.map-expand) & {
		&__info {
			display: none;
		}
	}

	&__filter {
		font-size: 15px;
		left: 50%;
		position: absolute;
		top: 20px;
		transform: translateX(-50%);

		&-steps,
		&-form {
			background-color: use-color('white');
			border: solid 1px use-color('light-dim');
			border-radius: 25px;
			color: use-color('gray-light');
			padding: 10px;
		}

		&-steps {
			align-items: center;
			display: inline-flex;
			height: var(--filter-height);
			justify-content: flex-start;

			.button {
				border-radius: 22px;
				height: 33px;
				width: unset;
			}

			[type='submit'],
			[type='reset'] {
				margin-left: 7px;
			}
		}

		&-step {
			padding: 0 16px;
			@include eclipses-text(200px);

			&:not(:first-of-type):not(.selected) {
				border-left: solid 1px currentColor;
				border-radius: unset;
			}

			&.filled {
				color: use-color('dark');
				font-weight: 400;
			}

			&.selected {
				background-color: use-color('dark');
				color: white;
			}

			&.selected + .monitor__filter-step {
				border-left-color: transparent;
			}
		}

		&-form {
			display: inline-block;
			left: 50%;
			margin-top: 12px;
			padding: 10px 16px;
			position: relative;
			transform: translateX(-50%);
		}

		&:not(.open) &-form {
			display: none;
		}

		&-splitter {
			background-color: use-color('gray');
			height: 30px;
			width: 1px;
		}

		&-input {
			align-items: center;
			background-color: use-color('light');
			border: 1px solid use-color('light');
			border-radius: 6px;
			color: use-color('dark');
			display: flex;
			font-size: 12px;
			height: 30px;

			input {
				background: unset;
				border: 0;
				border-radius: 0;
				font-size: 15px;
				padding: 10px 12px;
				text-align: center;
				width: unset;

				&::placeholder {
					color: use-color('light-dim');
				}

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					appearance: none;
					margin: 0;
				}

				&[type='number'] {
					appearance: textfield;
				}

				&[disabled] {
					opacity: 0.6;
				}
			}

			&[data-name='latitude'],
			&[data-name='longitude'] {
				padding-left: 10px;

				input {
					text-align: right;
				}
			}

			&[data-name='radius'] {
				position: relative;
				--decor-x: 5px;

				input {
					width: 100px;
				}

				&::before,
				&::after {
					background-image: url('../../assets/icons/date-picker-month-hover.svg');
					background-size: 4px 10px;
					content: '';
					height: 10px;
					position: absolute;
					top: calc(50% - 5px);
					width: 4px;
				}

				&::before {
					left: var(--decor-x);
				}

				&::after {
					right: var(--decor-x);
					transform: rotate(180deg);
				}
			}
		}

		&-clear-button {
			align-items: center;
			background-color: use-color('gray');
			border-radius: 4px;
			display: inline-flex;
			height: 1em;
			justify-content: center;
			padding: 3px;
			width: 1em;

			.icon {
				--size: 0.9em;
			}
		}

		&-radius {
			&-select {
				align-items: center;
				border-right: solid 2px use-color('gray-light');
				color: use-color('white');
				display: flex;
				font-size: 12px;
				justify-content: center;
				line-height: 14px;
				width: 100%;

				&:last-child {
					border: 0;
				}

				&:hover,
				&:active {
					background-color: #5e6577;
				}

				&.checked {
					background-color: use-color('green');
				}

				&-layout {
					background-color: use-color('gray');
					border-radius: 3px;
					display: flex;
					height: 27px;
					padding: 3px;
					width: 250px;
				}
			}
		}

		&-address-option {
			align-items: center;
			display: flex;
			min-height: 2em;
			padding: 3px 7px;

			&:hover {
				background-color: use-color('light-dim');
			}

			&.checked {
				background-color: use-color('green');
			}
		}

		&-address-option {
			i {
				color: use-color('green');
				text-decoration: none;
			}
		}

		&-category {
			align-items: center;
			display: inline-flex;
			flex-direction: column;
			--name-height: 30px;
			justify-content: space-between;
			row-gap: 5px;
			--button-size: 52px;
			--icon-size: 28px;
			text-align: center;

			&-grid {
				align-items: flex-start;
				column-gap: 4px;
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				row-gap: 16px;
			}

			&-checkbox {
				display: none;
			}

			&-button {
				align-items: center;
				border: solid 3px use-color('light');
				border-radius: calc(var(--button-size) / 3.5);
				display: inline-flex;
				height: var(--button-size);
				justify-content: center;
				width: var(--button-size);

				.icon {
					--size: var(--icon-size);
				}

				&:hover {
					border-color: use-color('light-dim');
				}
			}

			&-name {
				display: inline-block;
				font-size: 12px;
				font-weight: 400;
			}

			&.checked & {
				&-button {
					background-color: use-color('dark');
					border-color: use-color('dark');
					color: white;
				}

				&-name {
					color: use-color('dark');
				}
			}

			&-strict {
				font-size: 13px;
				font-weight: 400;
				margin-top: 15px;
			}
		}

		&-cost {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
			width: 400px;
		}

		&-clean {
			background-image: url('../../assets/icons/monitors-filter-clean.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 16px;
			border-radius: 50%;
			height: 16px;
			width: 16px;

			&:hover,
			&:active {
				background-image: url('../../assets/icons/monitors-filter-clean-active.svg');
			}
		}

		&-status {
			display: block;
			text-align: center;
		}
	}

	&__table {
		&-filter {
			border: dashed 1px use-color('gray-light');
			border-radius: 0.5em;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
			margin-bottom: 1em;

			&.form {
				padding: 9px 16px;

				fieldset {
					span {
						font-size: 13px;
					}
				}
			}
		}
	}

	&__orientation {
		&__layout {
			align-items: baseline;
			font-size: 14px;
			justify-content: space-around;
			position: relative;
		}

		&__icon {
			[type='radio'] {
				display: none;
			}

			.icon {
				--size: 165px;
			}
		}
	}

	&-precalc-form {
		align-items: center;
		column-gap: 12px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		padding: 12px 8px 10px;

		&__calendar {
			align-items: center;
			background: use-color('gray');
			border: solid 2px use-color('gray');
			border-radius: 4px;
			color: white;
			column-gap: 6px;
			display: flex;
			font-size: 14px;
			padding-left: 4px;

			.react-date-picker,
			.react-daterange-picker {
				&__wrapper {
					//padding: 2px;
				}

				&__inputGroup {
					background: white;
					height: 21.2px;
					padding: 0 12px;
					width: unset;
					//color: white;
				}
			}
		}

		&__output {
			background: white;
			border: solid 3px use-color('dark');
			border-radius: 4px;
			display: flex;
			flex-wrap: nowrap;
			font-size: 14px;
			padding: 4px 10px 4px 5px;
			white-space: nowrap;
		}

		&__button {
			font-size: 14px;
			height: 33px;
			white-space: nowrap;
			width: 150px;
		}
	}

	&-group {
		&__tab {
			align-items: center;
			border-bottom: solid 1px use-color('gray');
			display: flex;
			margin-bottom: 30px;

			&-item {
				align-items: center;
				background-color: use-color('light-dim');
				border-radius: 16px 16px 0 0;
				color: use-color('gray');
				cursor: pointer;
				display: flex;
				font-size: 16px;
				height: 43px;
				padding: 0 20px;
				text-transform: uppercase;
				transition:
					background-color 0.5s,
					color 0.75s;

				&:hover {
					background-color: use-color('gray-light');
					color: white;
				}

				&.active {
					background-color: use-color('gray');
					color: white;
				}
			}

			&-icon {
				background-image: var(--image-url);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: contain;
				height: 22px;
				margin-right: 10px;
				width: 23px;

				&.mirror {
					--image-url: url('../../assets/icons/monitors-group-sync.svg');
					width: 30px;

					&.active {
						--image-url: url('../../assets/icons/monitors-group-sync-white.svg');
					}
				}

				&.scaling {
					--image-url: url('../../assets/icons/monitors-group-wall.svg');

					&.active {
						--image-url: url('../../assets/icons/monitors-group-wall-white.svg');
					}
				}
			}
		}

		&__form {
			column-gap: 20px;
			display: grid;
			grid-template-columns: 320px 0.5fr 1fr;
			grid-template-rows: repeat(2, auto);
			padding: 40px 0 100px;
			row-gap: 20px;

			legend {
				font-size: calc(1em - 2px);
				font-weight: 500;
				margin-bottom: 30px;
				white-space: nowrap;
			}

			fieldset {
				display: inline-grid;

				&[name='wall'] {
					.monitor-wall,
					.monitor-group__notice {
						align-self: end;
					}
				}

				&[name='conditions'] {
					.add__edit__label {
						@media screen and (min-width: 1600px) {
							white-space: nowrap;
						}
					}

					input {
						width: 10vw;

						@media screen and (max-width: 1280px) {
							width: 120px;
						}
					}
				}

				&[name='groupName'] {
					align-items: center;
					column-gap: 10px;
					display: flex;
					grid-column: 1 / 3;
					grid-row: 2;
					justify-content: flex-start;

					input[name] {
						border: solid 1px use-color('gray');
						border-radius: 3px;
						height: 40px;
						padding: 0 10px;
						width: 150px;
					}

					label {
						white-space: nowrap;
					}
				}
			}

			button[type='reset'] {
				background-color: use-color('red');
			}
		}

		&__table {
			max-height: 38vh;

			th {
				z-index: 1;
			}
		}

		&__checklist {
			max-height: 190px;
			overflow: auto;

			table {
				border-collapse: separate;
				border-spacing: 0 0.5em;
			}

			tr {
				&.blocked {
					color: use-color('red');
					opacity: 0.5;
				}

				td {
					height: 2em;
					padding-right: 0.33em;
					vertical-align: middle;
					white-space: nowrap;

					&:first-child {
						white-space: nowrap;
					}

					&:not(:first-child) {
						border-left: solid 2px use-color('light-dim');
						padding-left: 0.33em;
					}

					> .icon,
					> b {
						--size: 17px;
						margin-right: 0.75em;
						vertical-align: middle;
					}

					> .icon {
						cursor: pointer;
					}

					> b {
						color: use-color('gray-light');
						display: inline-block;
						font-weight: 500;
						text-align: center;
						width: var(--size);
					}
				}
			}

			&.empty {
				border-bottom: dotted 1px use-color('gray');
			}
		}

		&__conditions {
			padding-top: 0.3em;
		}

		&__notice {
			background-color: use-color('light-dim');
			border-radius: 3px;
			display: inline-block;
			font-size: 12px;
			margin: 10px 0;
			padding: 7px 8px;
		}
	}

	&-wall {
		padding: 0 30px;
		position: relative;

		&__arrow {
			align-items: center;
			background-color: use-color('light-dim');
			border-radius: 50%;
			color: white;
			display: inline-flex;
			font-size: 16px;
			font-weight: bold;
			height: 23px;
			justify-content: center;
			padding: 4px;
			position: absolute;
			top: calc(50% - 11.5px);
			width: 23px;

			&.prev {
				left: 0;
			}

			&.next {
				right: 0;
			}
		}

		&__dnd {
			display: grid;
			gap: 10px;
			grid-auto-flow: row; /* or 'row', 'row dense', 'column dense' */
			grid-template: repeat(var(--row-length), 1fr) / repeat(
					var(--col-length),
					1fr
				);
			--col-length: 3;
			--row-length: 2;

			.dnd__droppable {
				border: dotted 2px use-color('dark');
				border-radius: 6px;
				padding: 3px;
			}
		}

		&__item {
			background: use-color('dark');
			border-radius: 5px;
			display: flex;
			height: 43px;
			justify-content: center;
			padding: 4px 0;

			&-label {
				align-items: center;
				background: use-color('gray');
				border: solid 2px use-color('light-dim');
				border-radius: 50%;
				color: use-color('light-dim');
				display: inline-flex;
				font-size: 14px;
				font-weight: 500;
				height: 35px;
				justify-content: center;
				opacity: 0.8;
				padding: 4px;
				width: 35px;
			}

			&[draggable='false'] {
				background: transparent;
				cursor: default;
			}
		}
	}
}

.screenshot {
	&__layout {
		align-items: center;
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}

	&__image {
		margin-right: 20px;
		width: 100px;
	}

	&__button {
		height: 60px;
		width: 200px;
	}
}

.add__edit {
	&__wrapper {
		height: unset;
	}

	&__address {
		.add__edit__form__field:not(:first-child) {
			margin-top: 24px;
		}

		&__map {
			height: 300px;
		}
	}

	&__coords {
		display: flex;
		margin-bottom: 15px;
		width: 300px;

		.add__edit__form__field,
		.add__edit__form__field:not(:first-child) {
			margin-top: 0;

			.form__input {
				width: 95%;
			}
		}
	}

	&__gps {
		appearance: textfield;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			appearance: none;
			margin: 0;
		}
	}
}

.main__content {
	.monitor {
		&:first-child:last-child {
			margin-top: 0;
			padding-left: 0;
			padding-right: 0;
		}
	}
}
