@import '../../styles/variables';

.main {
	&__content {
		padding-bottom: 0 !important;

		.editor__layout:first-child:last-child {
			margin-top: 0;
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.editor {
	&__layout {
		--timeline-body-height: 170px;
		--timeline-bottom-height: 36px;
		--timeline-height: calc(
			var(--timeline-body-height) + var(--timeline-bottom-height)
		);
		display: grid;
		grid-template-areas:
			'library player'
			'timeline timeline';
		grid-template-rows:
			calc(100vh - var(--timeline-height) - var(--header-height))
			var(--timeline-height);
		grid-template-columns: 430px 1fr;
	}

	&__content {
		background-color: #eef0f6;
		grid-area: library;
		display: grid;
		grid-template-rows: auto 1fr auto;
		padding-bottom: 10px;

		&__item {
			align-items: center;
			color: use-color('white');
			cursor: pointer;
			display: flex;
			font-size: 18px;
			height: 100%;
			justify-content: center;
			line-height: 21px;
			position: relative;
			text-transform: lowercase;
			transition: all 0.3s;
			width: 100%;

			&:hover {
				&::after {
					border-bottom: 14px solid use-color('light');
					border-left: 22px solid transparent;
					border-right: 22px solid transparent;
					bottom: 0;
					content: '';
					height: 0;
					position: absolute;
					width: 0;
				}
			}

			&:active {
				&::before {
					border-bottom: 17px solid use-color('dark-green');
					border-left: 26px solid transparent;
					border-right: 26px solid transparent;
					bottom: 0;
					content: '';
					height: 0;
					position: absolute;
					width: 0;
				}

				&::after {
					border-bottom: 14px solid use-color('light');
					border-left: 22px solid transparent;
					border-right: 22px solid transparent;
					bottom: 0;
					content: '';
					height: 0;
					position: absolute;
					width: 0;
				}
			}

			&--active {
				&::after {
					border-bottom: 14px solid use-color('light');
					border-left: 22px solid transparent;
					border-right: 22px solid transparent;
					bottom: 0;
					content: '';
					height: 0;
					position: absolute;
					width: 0;
				}
			}
		}

		&__bar {
			align-items: center;
			background-color: use-color('green');
			display: flex;
			height: 56px;
			justify-content: space-evenly;
			transition: all 0.3s;
		}

		.explorer {
			overflow: auto;

			&__dnd {
				padding: 10px 10px 50px;
			}
		}
	}

	&__player {
		background-color: use-color('black');
		display: grid;
		grid-template-rows: auto 1fr auto;

		> .player__video-container {
			display: flex;
			overflow: hidden;

			.preview-canvas {
				flex: 1;
			}
		}
	}
}

.video-library {
	display: flex;
	flex-direction: column;
	padding: 10px;
	row-gap: 10px;

	&__layout {
		align-items: baseline;
		display: flex;
		flex-direction: row;
		gap: 10px;
		height: 394px;
		overflow: auto;
	}

	&__path {
		font-size: 14px;
		line-height: 16px;
		max-width: 85%;

		&-layout {
			align-items: center;
			background-color: use-color('light-dim');
			border-radius: 5px;
			display: flex;
			padding: 6px 10px;
		}

		&-circle {
			background-image: url('../../assets/icons/library-up-menu.svg');
			background-repeat: no-repeat;
			background-size: 20px;
			height: 20px;
			margin-right: 10px;
			width: 20px;
		}

		&-item {
			color: use-color('dark');

			&:active {
				color: #0a0a0a;
			}
		}
	}

	&__folders {
		align-items: center;
		background-image: url('../../assets/icons/bottom-arrow.svg');
		background-position: 10px 4px;
		background-repeat: no-repeat;
		background-size: 20px;
		cursor: pointer;
		display: flex;
		height: 30px;
		padding-left: 40px;

		&-wrapper {
			height: 30px;
		}

		&-layout {
			background-color: use-color('white');
			border: 1px solid use-color('light-dim');
			border-radius: 5px;
			font-size: 14px;
			line-height: 16px;
			overflow: hidden;
			position: relative;
			z-index: 1;
		}

		&--active {
			background-image: url('../../assets/icons/top-arrow.svg');
		}
	}

	&__folder {
		align-items: center;
		background-color: use-color('white');
		cursor: pointer;
		display: flex;
		height: 30px;
		padding-left: 40px;

		&:hover {
			background-color: use-color('light');
		}
	}

	&__item {
		align-self: flex-start;
		background: use-color('light-dim');
		position: relative;
		width: 140px;
	}

	&__format {
		background: use-color('green');
		border-radius: 3px;
		color: use-color('white');
		font-size: 12px;
		font-weight: 600;
		height: 18px;
		padding: 4px;
		position: absolute;
		right: 20px;
		text-align: center;
		text-transform: uppercase;
		top: 17px;
		width: 32px;
	}

	&__preview {
		background-color: use-color('light-dim');
		display: flex;
		height: 104px;
		width: 140px;

		&-image {
			max-height: 100%;
			min-width: 100%;
			object-fit: contain;
		}
	}

	&__checkbox {
		left: 25px;
		position: absolute !important;
		top: 75px;

		div {
			background-size: 9px;
			height: 12px;
			width: 12px;
		}
	}

	&__info {
		font-size: 12px;
		line-height: 14px;
		text-align: center;

		&-container {
			background-color: use-color('white');
			border: solid 1px use-color(dark);
			margin: 0 auto;
			overflow: hidden;
			padding: 3px;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 130px;
		}
	}

	&__name {
		display: block;
		overflow: hidden;
		padding: 6px 9px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__size {
		background: use-color('white');
		border: 1px solid use-color('dark');
		display: inline-block;
		height: 20px;
		padding: 3px 0;
		width: 65px;
	}

	&__time,
	&__file-size {
		padding: 0 0.5em;
	}

	&__time {
		border-right: solid 1px use-color(dark);
	}

	&__size {
		border-top: 0;
		width: 130px;
	}

	&__actions {
		--actions-height: 40px;
		align-items: center;
		background: use-color('dark');
		border-radius: 6px;
		display: flex;
		height: var(--actions-height);
		justify-content: space-around;
		margin: 0 auto;
		max-width: 440px;
		position: relative;
		width: 95%;

		&-form {
			background-color: use-color('light');
			border-radius: 4px;
			border: solid 1px use-color('gray');
			box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
			color: use-color('dark');
			font-size: 13px;
			position: absolute;
			bottom: calc(var(--actions-height) + 8px);
			left: 0;
			row-gap: 8px;
			width: 80%;
			padding: 5px 10px;

			.form {
				padding: 0;
			}
		}

		&-title {
			font-weight: 500;
			padding: 3px 0;
		}
	}

	&__action {
		// align-items: center;
		color: use-color('white');
		column-gap: 10px;
		line-height: 20px;
		display: inline-flex;
		font-size: 12px;

		.icon {
			--size: 18px;
		}
	}

	&-duration-form {
		align-items: center;
		flex-direction: row;
		column-gap: 5px;
		padding: 0;

		&__count {
			font-weight: 500;
		}

		.input {
			&[data-name='duration'] {
				--width: 43px;
			}
		}
	}
}

.player {
	&__layout {
		grid-area: player;
	}

	&__video-container {
		position: relative;
	}

	&.fullscreen {
		border-radius: 0;
		height: 100vh;
		left: 0;
		margin: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1001;
	}

	&__bar {
		align-items: center;
		display: flex;
		gap: 15px;
		justify-content: space-between;
		padding: 20px 20px 15px;
		position: relative;

		&-right {
			column-gap: 15px;
			display: flex;
		}
	}

	&__button {
		background-position: left;
		background-repeat: no-repeat;
		display: inline-block;
		margin-left: 20px;
		transition: all 0.2s;
	}

	&__rename {
		&-project {
			align-items: center;
			background-color: use-color('dark-light');
			border-radius: 5px;
			display: flex;
			height: 40px;
			padding: 0 15px;
		}

		&-input {
			color: use-color('white');
			overflow: hidden;
			text-align: left;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 275px;
		}

		&-button {
			background-color: use-color('dark-green');
			border-radius: 4px;
			color: use-color('white');
			height: 40px;
			margin-left: 5px;
			width: 140px;
		}
	}

	&__save-as-button {
		background-image: url('../../assets/icons/save-as.svg');
		background-size: 24px;
		height: 25px;
		width: 26px;

		&:hover,
		&:active {
			background-image: url('../../assets/icons/save-as-hover.svg');
		}
	}

	&__cancel-project {
		background-image: url('../../assets/icons/cancel-project.svg');
		background-size: 20px 24px;
		height: 24px;
		width: 20px;

		&:hover,
		&:active {
			background-image: url('../../assets/icons/cancel-project-hover.svg');
		}
	}

	&__button-rendering {
		background-color: use-color('dark-green');
		border-radius: 4px;
		color: use-color('white');
		height: 40px;
		width: 140px;
	}

	&__file-name {
		position: relative;
	}

	&__send {
		background-color: use-color('dark-green');
		background-image: url('../../assets/icons/send.svg');
		background-position: 110px center;
		background-repeat: no-repeat;
		border-radius: 4px;
		color: use-color('white');
		height: 40px;
		padding-right: 31px;
		width: 140px;
	}

	&__devices {
		background: use-color('dark');
		border-radius: 5px;
		box-shadow: 0 3px 4px;
		height: 440px;
		position: absolute;
		right: 0;
		top: calc(100% + 10px);
		width: 850px;
		z-index: 101;

		.table {
			font-size: 14px;
			--td-height: 36px;
			height: 400px;
			--td-width: 100px;
		}

		.table__icon-name {
			padding-right: 10px;
		}

		&-info {
			align-items: center;
			color: use-color('white');
			display: flex;
			font-size: 10px;
			height: 40px;
			justify-content: space-between;
			padding: 0 40px 0 20px;
		}

		&-name {
			background-image: url('../../assets/icons/status-sort.svg');
			display: inline-block;
			height: 14px;
			margin: 0 15px 0 7px;
			width: 14px;
		}

		&-left {
			align-items: center;
			display: flex;
		}

		&-right {
			text-transform: lowercase;
		}

		&-total,
		&-selected,
		&-button,
		&-find {
			display: inline-block;
		}

		&-find {
			padding: 0;

			.form__field {
				color: use-color('dark');
			}
		}

		&-button {
			background-image: url('../../assets/icons/player-button.svg');
			height: 10px;
			margin-right: 17px;
			width: 10px;
		}

		&-input {
			background-color: use-color('white');
			border-radius: 3px;
			font-size: 10px;
			height: 20px;
			margin-left: 10px;
			padding: 10px;
			text-align: right;
			width: 150px;
		}

		&-find-button {
			background-image: url('../../assets/icons/player-search-devices.svg');
			background-repeat: no-repeat;
			height: 18px;
			margin-left: 10px;
			width: 16px;
		}

		&-total {
			margin-right: 20px;
		}
	}

	&__control {
		margin: 8px 0;

		&-layout {
			height: 60px;
			margin: 0 auto;
			width: 60px;
		}

		&-button {
			background-image: url('../../assets/icons/play-button.svg');
			border-radius: 50%;
			height: 60px;
			position: relative;
			transition: all 0.1s;
			width: 60px;
			z-index: 1;

			&:hover {
				background-image: url('../../assets/icons/play-button-hover.svg');
			}

			&--active {
				background-image: url('../../assets/icons/pause-button.svg');

				&:hover {
					background-image: url('../../assets/icons/pause-button-hover.svg');
				}
			}
		}
	}

	&__time {
		bottom: 20px;
		color: use-color('light-dim');
		font-size: 16px;
		line-height: 19px;
		position: absolute;
		right: 20px;
	}
}

.timeline-controls {
	&__layout {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 10px 20px;
		width: 100%;
	}

	&__buttons {
		align-items: center;
		display: flex;
		justify-content: space-between;
		width: 42%;
	}

	&__button {
		background-position: center;
		background-repeat: no-repeat;
		border: 2px solid transparent;
		border-radius: 5px;
		height: 58px;
		width: 58px;

		&:hover {
			border: 2px solid use-color('light-dim');
		}

		&:active {
			border: 2px solid use-color('gray');
		}
	}

	&__zoom {
		background-image: url('../../assets/icons/timeline-controls-zoom.svg');
		background-position: center;
		background-repeat: no-repeat;

		@media (max-width: 1550px) {
			background-size: 100px;
			height: 30px;
			width: 100px;
		}

		&--out {
			background-position-x: left;
			height: 58px;
			width: 60px;
		}

		&--in {
			background-position-x: right;
			height: 58px;
			width: 58px;
		}
	}

	&__cursor {
		background-image: url('../../assets/icons/timeline-controls-cursor.svg');
	}

	&__backward {
		background-image: url('../../assets/icons/timeline-controls-backward.svg');
	}

	&__forward {
		background-image: url('../../assets/icons/timeline-controls-forward.svg');
	}

	&__cut {
		background-image: url('../../assets/icons/timeline-controls-cut.svg');
	}

	&__delete {
		background-image: url('../../assets/icons/timeline-controls-delete.svg');
		background-position-x: 10px;
	}

	&__select {
		background-image: url('../../assets/icons/timeline-controls-select.svg');
	}

	&__time-display {
		position: relative;
	}

	&__time {
		background: linear-gradient(
			90deg,
			use-color('gray-light') 66.33%,
			use-color('gray') 73.33%
		);
		border-radius: 6px;
		color: use-color('white');
		display: inline-block;
		font-size: 16px;
		height: 40px;
		line-height: 19px;
		padding: 10px 25px;
		width: 150px;
	}

	&__time-button {
		background: use-color('green');
		background-image: url('../../assets/icons/white-arrow.svg');
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 6px;
		height: 40px;
		position: absolute;
		right: 0;
		width: 40px;
	}
}

.timeline {
	--timestamps-height: 20px;
	display: grid;
	grid-area: timeline;
	grid-template-areas: 'aside body' 'bottom bottom';
	grid-template-columns: auto 1fr;
	grid-template-rows: var(--timeline-body-height) var(--timeline-bottom-height);
	overflow: hidden;
	user-select: none;

	&__stamps {
		display: flex;
		position: relative;
		z-index: 100;
	}

	&__stamps-container {
		position: relative;
	}

	&__line {
		background-color: use-color('light-dim');
		bottom: 0;
		height: calc(var(--timestamps-height) / 2);
		left: 0;
		position: absolute;
		right: 0;
		z-index: -1;
	}

	&__unit {
		background: use-color('light-dim');
		height: var(--timestamps-height);
	}

	&__unit-time {
		background: use-color('gray');
		color: use-color('white');
		font-size: 8px;
		height: 10px;
		line-height: 9px;
		position: relative;
	}

	&__unit,
	&__unit-time {
		width: var(--min-scale-length);
	}

	&__unit-time span {
		display: block;
		left: 100%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 100;
	}

	&__unit-scale {
		display: flex;
		height: 50%;
		justify-content: space-between;
		margin: 0 0 0 -1px;
		width: calc(100% + 1px);

		span {
			background: use-color('gray');
			display: block;
			height: 50%;
			width: 0.5px;

			&:nth-child(1) {
				background: 0;
			}

			&:nth-child(6) {
				height: 100%;
				width: 1.5px;
			}
		}
	}

	&__aside,
	&__body {
		height: 170px;
	}

	&__aside {
		grid-area: aside;
		padding: var(--timestamps-height) 40px 0;
	}

	&__body {
		grid-area: body;
		overflow: hidden;
		overflow-x: auto;
		position: relative;

		.timeline__track {
			&-layout {
				width: var(--timeline-body-width);
			}
		}
	}

	&__bottom {
		align-items: center;
		border-top: dotted 1px use-color('dark');
		display: flex;
		grid-area: bottom;
		padding-left: 130px;
	}

	&__pointer {
		background-image: url('../../assets/icons/timeline-pointer.svg');
		cursor: grab;
		height: 150px;
		left: 0;
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		width: 20px;
		z-index: 100;

		&:active {
			cursor: grabbing;
		}

		&--preview {
			cursor: none;
			opacity: 0.4;
			visibility: hidden;
			z-index: 81;

			&:active {
				cursor: none;
			}
		}
	}

	&__track {
		&-layout {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-top: 15px;
		}

		&-icon {
			background-position: center;
			background-repeat: no-repeat;
			height: 50px;
			width: 50px;

			&--video {
				background-image: url('../../assets/icons/timeline-video.svg');
			}

			&--audio {
				background-image: url('../../assets/icons/timeline-audio.svg');
			}
		}

		&-body {
			background-color: use-color('light-dim');
			border-radius: 5px;
			display: flex;
			height: 50px;
			width: 100%;
		}
	}

	&-duration-form {
		align-items: center;
		column-gap: 5px;
		font-size: 13px;
		display: flex;

		.form {
			flex-direction: row;
			padding: 0;
		}

		&__count {
			font-weight: 500;
		}

		&__button {
			cursor: pointer;
		}

		.input {
			&[data-name='duration'] {
				--width: 43px;

				input {
					padding-left: 4px;
				}
			}
		}
	}
}

.file-track {
	&__layout {
		align-items: center;
		background: use-color('gray');
		background-image: url('../../assets/icons/file-track-gradient.svg');
		background-position-x: left;
		background-repeat: no-repeat;
		border-radius: 5px;
		color: use-color('white');
		cursor: grab;
		display: flex;
		font-size: 12px;
		height: 50px;
		justify-content: space-between;
		line-height: 14px;
		overflow: hidden;
		position: absolute;
		z-index: 91;

		&:active {
			cursor: grabbing;
		}

		&--prev {
			.file-track__group-right {
				background-image: linear-gradient(to left, #f00, transparent 20%);
			}
		}

		&--next {
			.file-track__group-left {
				background-image: linear-gradient(to right, #f00, transparent 20%);
			}
		}

		&--select {
			opacity: 0.68;
			transform: scale(1.25);
			z-index: 92;
		}
	}

	&__group {
		align-items: center;
		display: grid;
		grid-template-columns: auto 1fr;
		height: 50px;
	}

	&__group-left {
		background-color: use-color('gray');
		left: 0;
		padding-right: 1em;
		position: sticky;

		&:active {
			&::before {
				background-image: url('../../assets/icons/track-move-left.svg');
				background-position: center;
				background-repeat: no-repeat;
				content: '';
				height: 50px;
				left: -18px;
				position: absolute;
				width: 24px;
			}
		}
	}

	&__group-right {
		&:active {
			&::after {
				background-image: url('../../assets/icons/track-move-right.svg');
				content: '';
				height: 50px;
				position: absolute;
				right: -12px;
				width: 24px;
			}
		}
	}

	&__file-icon {
		background-position: center;
		background-repeat: no-repeat;
		display: inline-block;
		height: 12px;
		margin: 0 10px;
		width: 18px;

		&--video {
			background-image: url('../../assets/icons/video-file.svg');
		}

		&--audio {
			background-image: url('../../assets/icons/audio-file.svg');
		}

		&--image {
			background-image: url('../../assets/icons/image-file.svg');
		}
	}

	&__time {
		font-size: 14px;
		line-height: 16px;
		margin-right: 12px;
	}

	&__audio {
		background-image: url('../../assets/icons/file-track-audio.svg');
		background-position: center;
		background-repeat: no-repeat;
		cursor: pointer;
		display: inline-block;
		height: 20px;
		margin-right: 15px;
		width: 26px;

		&--off {
			background-image: url('../../assets/icons/file-track-audio-off.svg');
			width: 28px;
		}
	}

	&__name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.project-name {
	&__icon {
		background-image: url('../../assets/icons/video-project-icon.svg');
		height: 55px;
		margin: 0 auto 18px;
		width: 60px;
	}

	&__title {
		color: use-color('white');
		font-size: 18px;
		font-weight: 400;
		line-height: 21px;
		margin-bottom: 10px;
		text-align: center;
	}

	&__input {
		background-color: use-color('white');
		background-image: url('../../assets/images/loader.png');
		background-position-x: 360px;
		background-position-y: center;
		background-repeat: no-repeat;
		background-size: 30px;
		border-radius: 10px;
		height: 40px;
		margin-bottom: 20px;
		padding: 10px;
		width: 400px;
	}

	&__create-button {
		background-color: use-color('dark-green');
		border: 3px solid use-color('dark-green');
		border-radius: 4px;
		color: use-color('white');
		display: block;
		height: 40px;
		margin: 0 auto;
		width: 140px;

		&:hover {
			border: 3px solid use-color('light-dim');
		}

		&:active {
			border: 3px solid rgba(0, 0, 0, 0.2);
		}
	}

	&__error-container {
		background-color: use-color('red');
		border-radius: 10px;
		color: use-color('white');
		height: 80px;
		margin-top: 20px;
		padding: 10px 0;
		width: 400px;
	}

	&__error-title {
		background-image: url('../../assets/icons/project-error-icon.svg');
		background-position-x: left;
		background-position-y: center;
		background-repeat: no-repeat;
		background-size: 20px 18px;
		color: use-color('white');
		font-size: 18px;
		font-weight: 400;
		height: 22px;
		line-height: 21px;
		margin-left: 139px;
		text-align: center;
		width: 130px;
	}

	&__error-value {
		margin-top: 14px;
		text-align: center;
	}

	&__form {
		padding: 0;
	}
}

.main {
	&__content {
		padding-bottom: 0;
	}
}
