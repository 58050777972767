@import './fonts';

$palette-colors: (
	'main': #f60,
	'black': #1c1c1c,
	'dark': #474a52,
	'dark-light': #535c73,
	'gray': #697185,
	'gray-light': #888fa3,
	'light-dim': #cdd1df,
	'light': #eef0f6,
	'white': #fff,
	'green': #32c832,
	'dark-green': #00a800,
	'night-green': #026602,
	'red': #e41146,
	'purple': #8511e4,
	'orange': #e4ad11,
);

$inactive-opacity: 0.4;
$hover-opacity: 0.8;
$main-transition: all 0.2s ease-in-out;

$tablet-width: 768px;
$desktop-width: 1240px;
$max-desktop-width: 1040px;
$content-width: 1200px;
$max-content-width: 1440px;

$demobar-height: 40px;
$header-height: 55px;
$sidebar-width: 120px;
$sidebar-width--open: 300px;
$content-padding: 20px;

@function use-color($key: 'dark') {
	@return map-get($palette-colors, $key);
}

@mixin mobile {
	@media (max-width: #{$tablet-width - 1px}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}

@mixin landscape {
	@media (orientation: landscape) {
		@content;
	}
}

@mixin backdrop-filter($val) {
	backdrop-filter: $val;
}

@mixin eclipses-text($width) {
	max-width: $width;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

@mixin demobar-gradient() {
	background-image: linear-gradient(
		to right,
		use-color('red'),
		use-color('orange'),
		use-color('dark-green')
	);
}
