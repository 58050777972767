@import '../../styles/variables';

.library {
	.add__edit__title {
		font-weight: normal;
	}

	&__head {
		&.flex.row {
			column-gap: 18px;
		}

		.icon {
			--size: 27px;
			cursor: pointer;

			&[data-action='Create directory'] {
				--size: 36px;
			}

			&[data-action='Cut'] {
				--size: 32px;
			}

			&[data-action='Delete'] {
				--size: 26px;
			}
		}
	}

	&__explorer {
		margin-top: 10px;
	}
}
