@import 'styles/variables';

.confirmation {
	&__layout {
		background-color: rgba(205, 209, 223, 0.7);
		height: 100vh;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1000;

		&:first-child:last-child {
			margin-top: 0 !important;
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__window {
		align-items: center;
		background: use-color('gray');
		border-radius: 10px;
		display: flex;
		font-size: 14px;
		height: 200px;
		justify-content: center;
		left: 50%;
		line-height: 16px;
		padding: 0 50px;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -70%);
		width: 400px;
	}

	&__text {
		align-items: center;
		background-image: url('../../../assets/icons/warning.svg');
		background-repeat: no-repeat;
		color: use-color('white');
		display: flex;
		min-height: 30px;
		padding-left: 45px;
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
	}

	&__button {
		border-radius: 6px;
		color: use-color('white');
		height: 36px;
		text-transform: uppercase;
		width: 140px;

		&--confirm {
			background-color: use-color('dark-green');
		}

		&--cancel {
			background-color: use-color('red');
		}
	}
}
