@import '../../styles/variables';

.container {
	display: flex;
	flex-direction: row;
	flex-flow: row;
	height: 100vh;
	position: relative;
	width: 100%;

	&__boxed {
		align-self: center;
		background-color: use-color('white');
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
		flex-direction: column;
		flex-flow: column;
		margin: 0 auto;
		max-width: 600px;
		text-align: center;
		transition:
			width 0.5s linear,
			height 0.5s linear;
		width: 80%;

		@include tablet {
			@include landscape {
				align-self: auto;
				box-shadow: none;
				max-width: inherit;
				width: 100%;
			}
		}
		@include mobile {
			align-self: auto;
			box-shadow: none;
			max-width: inherit;
			width: 100%;
		}

		&-rounded {
			border-radius: 2em;
			@include mobile {
				border-radius: 0;
			}
		}

		&-flat {
			box-shadow: none;
		}

		&__title {
			font-size: 1.25em;
			font-weight: 500;
		}
	}

	&__title {
		&-margin {
			margin-top: 18px;
		}
	}

	&__text {
		&-margin {
			margin-top: 1.3em;
		}
	}
}
