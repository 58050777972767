@import '../../../styles/variables';

.button {
	border: 0;
	color: currentColor;
	cursor: pointer;
	display: inline-block;
	font-size: 1em;
	overflow: hidden;
	padding: 0;
	text-align: center;
	text-decoration: none;
	transition: all 0.3s;

	&-text {
		background-color: transparent;
		border-bottom: dashed 1px;

		&:hover,
		&:active {
			border-style: solid;
		}
	}

	&-primary {
		align-items: center;
		background: linear-gradient(
			use-color('dark-green'),
			use-color('dark-green')
		);
		border: 2px solid use-color('dark-green');
		border-radius: 10px;
		color: use-color('white');
		display: flex;
		font-weight: 400;
		height: 40px;
		justify-content: center;
		min-width: 110px;
		padding: 0 10px;

		&:hover {
			background: linear-gradient(
				180deg,
				use-color('dark-green') 0%,
				use-color('green') 100%
			);
		}

		&:active {
			background: linear-gradient(
				180deg,
				use-color('dark-green') 0%,
				use-color('green') 100%
			);
			border: 2px solid use-color('dark-green');
		}
	}

	&-text-primary {
		border-color: use-color('green');
		color: use-color('green');

		&:hover,
		&:active {
			border-color: darken(use-color('green'), 5%);
			color: darken(use-color('green'), 5%);
		}
	}

	&-secondary {
		background-color: use-color('gray');
		border-radius: 10px;
		color: use-color('light');
		font-weight: 400;
		height: 40px;
		line-height: 40px;
		min-width: 110px;
		padding-left: 10px;
		padding-right: 10px;

		&:hover,
		&:active {
			background-color: darken(use-color('gray'), 5%);
		}
	}

	&-text-secondary {
		border-color: use-color('gray');
		color: use-color('gray');

		&:hover,
		&:active {
			border-color: darken(use-color('gray'), 5%);
			color: darken(use-color('gray'), 5%);
		}
	}

	&-rounded {
		border-radius: 22px;
	}

	&[disabled] {
		cursor: not-allowed;
		opacity: 0.5;
	}

	.form__input__select {
		background-color: rgba(0, 0, 0, 0.3);
		color: currentColor;
		height: 100%;
		padding: 0 5px;
		width: unset;
	}
}
