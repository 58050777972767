@import 'styles/variables';

.pagination {
	align-items: center;
	background-color: use-color('white');
	border-radius: 1em;
	display: flex;
	flex-flow: row;
	font-size: 18px;
	height: 30px;
	justify-content: center;
	margin: 0 auto;
	width: fit-content;

	@include tablet {
		width: 70%;
	}
	@include mobile {
		margin: 0;
		width: 100%;
	}

	&__wrapper {
		margin: 1em auto;
	}

	&__pages {
		align-items: center;
		display: flex;
		height: 30px;
	}

	&__item {
		padding: 4px;
	}

	&__btn {
		align-items: center;
		appearance: none;
		background-color: transparent;
		border: 0;
		border-radius: 50%;
		display: flex;
		height: 22px;
		justify-content: center;
		transition: all 0.3s;
		width: 22px;

		&:hover,
		&:active {
			background-color: use-color(gray-light);
			color: use-color('white');
		}

		&--active {
			background-color: use-color('dark');
			color: use-color('white');
		}

		&-prev,
		&-next {
			background-image: url('../../assets/icons/circle-arrow.svg');
			background-position: center;
			background-repeat: no-repeat;
			font-size: 0;
			height: 26px;
			transform: rotate(0deg);
			width: 26px;
		}

		&-prev {
			transform: rotate(180deg);

			&--disabled {
				background-image: url('../../assets/icons/circle-arrow-disabled.svg');
				cursor: default;
			}
		}

		&-next {
			&--disabled {
				background-image: url('../../assets/icons/circle-arrow-disabled.svg');
				cursor: default;
			}
		}

		&-prev,
		&-next {
			&:hover,
			&:active {
				background-color: unset;
			}
		}

		&-first,
		&-last {
			background-image: url('../../assets/icons/double-circle-arrow.svg');
			font-size: 0;
			height: 22px;
			width: 22px;
		}

		&-first {
			transform: rotate(180deg);

			&--disabled {
				background-image: url('../../assets/icons/double-circle-arrow-disabled.svg');
			}
		}

		&-last {
			&--disabled {
				background-image: url('../../assets/icons/double-circle-arrow-disabled.svg');
			}
		}
	}
}
