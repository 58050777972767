@import '../../styles/variables';

.page-image {
	height: 6em;
}

.auth {
	&__field {
		margin-bottom: 45px;
	}

	&__reset-field {
		margin: 30px 0 175px;
	}

	&__button {
		background-color: use-color('green');
		font-size: 18px;
		font-weight: 500;
		line-height: 12px;
		width: 100%;

		&:hover {
			background-color: use-color('dark-green');
		}

		&--margin {
			margin-bottom: 30px;
		}
	}

	&__link {
		display: inline-block;
		margin-top: 16px;
	}

	&__icon {
		margin-bottom: 120px;
	}
}
