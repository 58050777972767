@import '../../styles/variables';

.playlists {
	&__wrapper {
		margin-top: 1em;

		.explorer__item {
			&-file-info {
				background-color: transparent;
				border: 0;

				&_duration {
					border: 0;
				}
			}
		}
	}

	&__table {
		max-height: 78vh;
	}

	&__item {
		&__action {
			justify-content: space-evenly;
			width: 100%;
		}
	}

	&__library {
		&__action {
			&_button {
				align-items: center;
				border-radius: 0.5em;
				color: use-color(dark);
				display: flex;
				flex-flow: row;
				justify-content: space-evenly;
				padding: 0.5em;
				transition: all 0.3s;
				width: 25%;

				&:hover,
				&:active {
					background-color: use-color(light-dim);
				}

				img {
					margin-right: 1em;
					max-height: 1.25em;
				}
			}
		}
	}

	&__link-button {
		border-radius: 5px;
		font-size: 16px;
		height: 50px;
		line-height: 21px;
		width: auto;

		@include tablet {
			flex-basis: 8%;
			padding: 0.5em;
		}
		@include mobile {
			flex-basis: 8%;
			padding: 0.5em;
		}
	}

	&__search-icon {
		--size: 30px;
		margin-right: 10px;
		transform: rotate(-90deg);

		@include mobile {
			height: 1em;
		}
	}
}

.map {
	&.monitor__table {
		margin-bottom: $content-padding;
		max-height: 36vh;
	}

	&.playlists__table {
		margin-top: $content-padding;
		max-height: 36vh;
		min-height: 390px;
	}
}

.add__edit {
	&__timeline {
		&__header,
		&__footer {
			align-items: center;
			background-color: use-color('gray');
			border-radius: 0.5em 0.5em 0 0;
			color: use-color('white');
			padding: 0.5em 1em;

			.form__input {
				background-color: use-color('white');
				border-color: use-color('gray');
				border-radius: 5px;
				color: use-color('dark');
				padding: 0 0.5em;
			}

			.form__field {
				margin: 0 1em;
			}

			&_item {
				padding: 0.5em 1em;
			}

			&__playlist-name {
				padding-right: 0;
			}
		}

		&__footer {
			border-radius: 0 0 0.5em 0.5em;
			border-top: dashed 1px use-color('white');
			justify-content: space-evenly;
		}

		&__button {
			align-items: center;
			border-radius: 0.5em;
			color: use-color('white');
			display: flex;
			justify-content: center;
			padding: 10px;
			transition: all 0.3s;

			&:hover,
			&:active {
				background-color: use-color('gray-light');
			}

			&-content {
				align-items: center;
				background-image: url('../../assets/icons/save-playlist.svg');
				background-position: 0 center;
				background-repeat: no-repeat;
				background-size: 20px 18px;
				display: flex;
				height: 20px;
				padding-left: 30px;
			}

			&_disabled {
				cursor: default;
				opacity: 0.5;

				&:hover,
				&:active {
					background-color: use-color('gray');
				}
			}
		}

		&__footer &__button {
			margin-left: 8px;

			&-content {
				background-image: url('../../assets/icons/delete-bin-white.svg');
				background-size: 20px;
			}
		}

		&__order {
			align-items: center;
			display: flex;
			flex-direction: column;
			height: 80px;
			justify-content: space-between;
		}

		&__order-button {
			background-image: url('../../assets/icons/top-arrow-light.svg');
			background-position: center;
			background-repeat: no-repeat;
			height: 20px;
			transition: all 0.3s;
			width: 20px;

			&[disabled] {
				opacity: 0.6;
			}

			&:not([disabled]):hover,
			&:not([disabled]):active {
				opacity: 0.9;
			}

			&.bottom {
				transform: rotate(180deg);
			}
		}

		&__content-wrapper {
			align-items: center;
			background-color: use-color('light-dim');
			display: flex;
			height: 30px;
			padding-left: 20px;
			width: 100%;
		}

		.table {
			border-collapse: separate;

			&__wrapper {
				background-color: use-color('gray-light');
				border-radius: 0;
				border-top: dashed 1px use-color('white') !important;

				.form__checkbox {
					> label {
						flex-direction: column;
						justify-content: center;
					}
				}
			}

			&__head {
				tr > th {
					background-color: use-color('gray');
					border-right: solid 2px use-color('white');
					min-width: auto;
					padding: 0.75em 1em;

					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3),
					&:last-child {
						border-right: 0;
					}

					&:nth-child(2) {
						width: 6em;
					}
				}
			}

			&__body {
				tr {
					background-color: use-color('gray-light');
					color: use-color('light-dim');

					> td {
						border-top: 1px dashed use-color('white');

						&:nth-child(1) {
							color: use-color('light-dim');
							font-size: 14px;
							font-weight: 500;
							line-height: 16px;
							text-align: center;
							width: 50px;
						}

						&:nth-child(3) {
							.form__checkbox {
								margin: 0;
							}
						}

						&:nth-child(3),
						&:nth-child(4) {
							min-width: 10px;
							width: 40px;
						}

						&:nth-child(4),
						&:nth-child(5),
						&:nth-child(6),
						&:nth-child(7) {
							color: use-color('dark');
							padding: 0;
						}

						&:nth-child(5) {
							min-width: 200px;
							width: 500px;
						}
					}
				}

				.explorer__item-preview {
					margin: 0;

					&_ext {
						display: none;
					}
				}
			}
		}

		&_ext {
			background-color: use-color('green');
			border-radius: 3px;
			color: use-color('white');
			display: block;
			font-size: 12px;
			font-weight: 700;
			padding: 6px;
			text-transform: uppercase;
			width: 37px;
		}
	}
}
