@import '../../styles/variables';

.error {
	&__wrapper {
		align-self: center;
		background-color: use-color('white');
		border: 1px solid use-color('gray-light');
		border-radius: 25px;
		height: 600px;
		justify-content: space-between;
		margin: 0 auto;
		padding: 175px 130px;
		width: 900px;

		@include tablet {
			padding: 4em;
			width: 80%;
		}
		@include mobile {
			align-self: auto;
			border: 0;
			border-radius: 0;
			height: 100%;
			justify-content: center;
			margin: 0;
			padding: 1em;
			width: 100%;
		}
	}

	&__content {
		flex-grow: 1;
	}

	&__message {
		color: use-color('gray-light');
	}

	&__heading {
		color: use-color('red');
		font-size: 64px;
		font-weight: 900;
		margin-bottom: 27px;

		@include mobile {
			font-size: 2em;
		}
	}

	&__message {
		font-size: 24px;
		margin-bottom: 7px;

		&:last-child {
			font-size: 14px;

			@include mobile {
				font-size: 1.5em;
			}
		}

		@include mobile {
			font-size: 0.75em;
		}
	}

	&__icon {
		background-image: url('../../assets/icons/error-page.svg');
		height: 117px;
		width: 127px;
	}

	&__reload,
	&__link {
		align-items: center;
		background: use-color('dark');
		border-radius: 5px;
		display: flex;
		height: 50px;
		justify-content: center;
		text-transform: uppercase;
		width: 150px;
	}

	&__reload {
		font-size: 24px;
	}
}
