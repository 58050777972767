@import '../../styles/variables';

.explorer {
	&__dnd {
		padding: 10px;

		&.grid {
			--gap: 45px;
			row-gap: calc(var(--gap) / 3);
		}

		.dnd__draggable {
			&[draggable='true'] {
				cursor: default;
			}
		}
	}

	&__item {
		align-items: center;
		border-radius: 5px;
		font-size: 12px;
		height: 170px;
		position: relative;
		text-align: center;
		transition: all 0.3s;
		width: 100px;

		&:hover {
			border-color: use-color('green');
			cursor: pointer;
		}

		&-filename {
			display: inline-block;
			margin-bottom: 7px;
			margin-top: 5px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 120px;
		}

		&-textarea {
			background-color: use-color('white');
			border: solid 2px use-color('green');
			border-radius: 5px;
			display: inline-block;
			margin-bottom: 1px;
			margin-top: 1px;
			-ms-overflow-style: none;
			resize: none;
			scrollbar-width: none;
			text-align: center;
			width: 100px;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		&-preview {
			margin-bottom: 10px;
			position: relative;

			&_image {
				border-radius: 3px;
				height: 75px;
				max-width: 140px;
				object-fit: contain;
				box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
			}

			&_ext {
				background-color: use-color('green');
				border-radius: 3px;
				color: use-color('white');
				font-size: 0.75em;
				font-weight: 600;
				padding: 0.35em;
				position: absolute;
				right: 0;
				text-align: center;
				text-transform: uppercase;
				top: 0;
			}

			&_checkbox {
				align-self: flex-start;
				margin-left: 5px;
				margin-top: -16px;
				transform: scale(0.75);
			}
		}

		&-status {
			align-items: center;
			display: flex;
			text-transform: lowercase;

			img {
				height: 0.75em;
				margin-right: 0.25em;
			}
		}

		&-file-info {
			background-color: use-color('white');
			border: solid 1px use-color(dark);
			margin: 5px 0;
			overflow: hidden;
			padding: 3px;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 130px;

			&_duration,
			&_size {
				padding: 0 0.5em;
			}

			&_duration {
				border-right: solid 1px use-color(dark);
			}
		}

		&-menu {
			background: use-color('light');
			border: solid 1px use-color('dark');
			border-radius: 5px;
			left: 95px;
			position: absolute;
			top: 95px;
			width: 180px;
			z-index: 1;
		}

		&-action {
			align-items: center;
			border-radius: 5px;
			cursor: pointer;
			display: flex;
			font-size: 14px;
			font-weight: 400;
			height: 33px;
			padding-left: 10px;

			&:hover,
			&:active {
				background-color: use-color('light-dim');
			}
		}

		&__button {
			&_add,
			&_remove {
				background-position: center;
				background-repeat: no-repeat;
				height: 20px;
				transition: all 0.3s;
				width: 20px;
			}

			&_remove {
				background-image: url('../../assets/icons/added-hover.svg');
				transform: rotate(-45deg);

				&:disabled {
					background-image: url('../../assets/icons/added.svg');
					cursor: default;
					opacity: 0.5;
				}

				&:not(:disabled):hover,
				&:not(:disabled):active {
					background: url('../../assets/icons/added.svg') no-repeat center;
				}
			}

			&_add {
				background: url('../../assets/icons/added.svg') no-repeat center;

				&:not(:disabled):hover,
				&:not(:disabled):active {
					background-image: url('../../assets/icons/added-hover.svg');
				}
			}
		}

		&-actions {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 100%;
		}

		&-download {
			color: use-color('dark');
			cursor: pointer;
			display: inline-block;
			margin-bottom: 8px;

			&:hover,
			&:active {
				color: #0a0a0a;
			}
		}

		&-checkbox {
			appearance: none;
			background-color: use-color('white');
			border: 2px solid use-color('green');
			border-radius: 2px;
			cursor: pointer;
			height: 12px;
			width: 12px;
			position: absolute;
			top: 62px;
			right: 0;

			&:checked {
				background-image: url('../../assets/icons/check-green.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: 10px;

				&:hover {
					background-color: use-color('green');
					background-image: url('../../assets/icons/check-hover.svg');
				}
			}
		}
	}

	&__folder-path-layout {
		align-items: center;
		background-color: use-color('light-dim');
		border-radius: 5px;
		display: flex;
		padding: 6px 10px;
		width: 100%;
	}

	&__path-circle {
		background-image: url('../../assets/icons/library-up-menu.svg');
		background-repeat: no-repeat;
		background-size: 20px;
		height: 20px;
		margin-right: 10px;
		width: 20px;
	}

	&__folder-path {
		font-size: 14px;
		line-height: 16px;
		max-width: 95%;
	}

	&__folder-path-item {
		color: use-color('dark');

		&:active {
			color: #0a0a0a;
		}
	}
}

.file-status {
	align-items: center;
	column-gap: 3px;
	display: inline-flex;
	justify-self: center;
	text-transform: lowercase;

	.icon {
		--size: 9px;
	}
}
