@import './variables';

.Toastify {
	&__toast-container--top-right {
		right: 150px;
		top: 45px;
	}

	&__toast-body {
		margin: 0;
		padding: 0;
	}

	&__toast-download-container {
		background-color: use-color('white');
		border: 0.5px solid #cdd1df;
		border-radius: 5px;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
		color: use-color('dark');
		height: 50px;
		min-height: 50px;
		overflow: visible;
		padding: 0;
		width: 250px;
	}
}

.toast {
	&__text {
		@include roboto;
		font-size: 12px;
		max-width: 300px;
	}

	&__title {
		font-weight: 500;
	}

	&__description {
		-webkit-box-orient: vertical;
		display: flex;
		font-weight: 300;
		-webkit-line-clamp: 2;
		margin-top: 3px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__close-button {
		background-image: url('../assets/icons/toast-close-button.svg');
		height: 11px;
		width: 11px;
	}

	&-task {
		align-items: center;
		border-radius: 5px;
		column-gap: 8px;
		display: flex;
		font-size: 13px;

		&__progress {
			&-percent {
				color: use-color('light-dim');
				font-size: 14px;
				font-weight: bold;
			}
		}

		&__content {
			flex: 1;
		}

		&.done & {
			&__progress {
				&-percent {
					color: use-color('green');
				}
			}
		}

		&.failed & {
			&__progress {
				&-percent {
					color: use-color('red');
				}
			}
		}
	}
}

.toast-download {
	&__body {
		align-items: center;
		display: flex;
		@include roboto;
		font-size: 9px;
	}

	&__icon {
		background-color: use-color('green');
		background-image: url('../assets/icons/download-progress-icon.svg');
		background-position-x: center;
		background-position-y: 8px;
		background-repeat: no-repeat;
		height: 50px;
		width: 50px;

		&--pause {
			background-color: use-color('dark');
			background-image: url('../assets/icons/download-pause-icon.svg');
		}

		&--error {
			background-color: use-color('red');
			background-image: url('../assets/icons/download-error-icon.svg');
		}

		&--success {
			background-image: url('../assets/icons/download-success-icon.svg');
		}
	}

	&__percent {
		color: use-color('white');
		display: flex;
		font-size: 10px;
		font-weight: 500;
		justify-content: center;
		margin-top: 35px;
	}

	&__content {
		position: relative;
		width: 200px;
	}

	&__info {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 0 10px;
	}

	&__title {
		display: block;
		font-size: 1.1em;
		font-weight: 500;
	}

	&__description {
		color: inherit;
		display: block;
		font-weight: 200;
		margin: 0.25em 0;
	}

	&__continue {
		background-image: url('../assets/icons/download-continue.svg');
		height: 8px;
		margin-right: 14px;
		width: 5px;

		&--error {
			background-image: url('../assets/icons/download-continue-dark.svg');
		}

		&--success {
			background-image: url('../assets/icons/download-continue-disabled.svg');
		}
	}

	&__pause {
		background-image: url('../assets/icons/download-pause.svg');
		height: 8px;
		margin-right: 14px;
		width: 6px;

		&--pause {
			background-image: url('../assets/icons/download-pause-dark.svg');
		}

		&--success {
			background-image: url('../assets/icons/download-pause-disabled.svg');
		}
	}

	&__button-wrapper {
		display: inline-block;
		position: relative;
	}

	&__close {
		background-image: url('../assets/icons/download-close.svg');
		height: 8px;
		width: 8px;

		&--pause {
			background-image: url('../assets/icons/download-close-dark.svg');
		}

		&--success {
			background-image: url('../assets/icons/download-close-disabled.svg');
		}
	}

	&__confirm-delete {
		background-image: url('../assets/icons/download-confirm-delete.png');
		background-repeat: no-repeat;
		background-size: 120px 60px;
		bottom: 5px;
		height: 60px;
		position: absolute;
		right: -56px;
		width: 120px;
		z-index: 1;
	}

	&__confirm-message {
		display: flex;
		font-size: 9px;
		font-weight: 200;
		justify-content: center;
		line-height: 11px;
		margin: 15px 0 0;
	}

	&__confirm-buttons {
		display: flex;
		font-size: 9px;
		font-weight: 200;
		margin: 3px 15px;
	}

	&__confirm-cancel {
		align-items: center;
		background-color: use-color('green');
		color: use-color('white');
		display: flex;
		height: 15px;
		justify-content: center;
		width: 40px;
	}

	&__confirm-ok {
		align-items: center;
		border: 1px solid use-color('gray-light');
		color: use-color('dark');
		display: flex;
		height: 15px;
		justify-content: center;
		margin-left: 10px;
		width: 40px;
	}

	&__progress-bar {
		background-color: use-color('light-dim');
		height: 4px;
		margin-top: 2px;
	}

	&__progress {
		background-color: use-color('green');
		height: 4px;
		--width-percent: 0%;
		width: var(--width-percent);

		&--paused {
			background-color: use-color('dark');
		}

		&--success {
			width: 100%;
		}
	}

	&__filesize {
		font-weight: 200;
		margin-top: 5px;
		padding: 0 10px;
	}
}
