@import './reset';
@import './variables';
@import './fonts';
@import './scrollbar';

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
	display: none;
}

html {
	overflow-x: hidden;
}

html,
body,
#root {
	background-color: use-color('light');
}

#root {
	background-color: use-color('light');
	color: use-color('dark');
	font-size: 16px;
	@include roboto;
	font-weight: 300;
	min-height: 100vh;
}

.app-logo {
	align-items: center;
	background: url('../assets/brand/logo.svg') center no-repeat use-color('dark');
	background-size: auto 60%;
	display: flex;
	height: 75px;
	justify-content: center;

	&.color {
		background-image: url('../assets/brand/logo-color.svg');
	}
}

a {
	color: use-color('white');
	text-decoration: none;

	&.link {
		color: use-color('dark');

		&:hover,
		&:active {
			color: darken(use-color('dark'), 50%);
		}
	}
}

.link-underline {
	text-decoration: underline;
}

.flex {
	display: flex;

	&.row {
		align-items: center;
		column-gap: 10px;
		flex-direction: row;
	}

	&.column {
		flex-direction: column;
	}
}

.error {
	border-color: use-color('red') !important;
	color: use-color('red') !important;
	fill: use-color('red') !important;
}

.grid {
	--gap: 10px;
	align-content: start;
	column-gap: var(--gap);
	display: flex;
	flex-wrap: wrap;
	row-gap: var(--gap);
}

.tab {
	align-items: center;
	border-bottom: solid 1px use-color('gray-light');
	display: flex;

	&__item {
		border-bottom: solid 2px transparent;
		color: use-color('gray');
		font-weight: 400;
		padding: 0 20px 15px;
		transition: color 0.5s;

		&:hover {
			color: use-color('dark');
		}

		&.active {
			border-bottom-color: use-color('gray-light');
			color: use-color('dark');
		}
	}
}

.checkbox {
	align-items: center;
	column-gap: 10px;
	display: inline-flex;

	[type='checkbox'] {
		display: none;
	}

	&__box {
		align-self: center;
		border: solid 2px use-color('dark-light');
		border-radius: 5px;
		height: 16px;
		position: relative;
		width: 16px;
	}

	&.checked & {
		&__box {
			background: use-color('dark-light');

			.icon {
				--size: 13px;
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	&:not(.checked) & {
		&__icon {
			display: none;
		}
	}
}

.input {
	--width: unset;

	input {
		width: var(--width);
	}

	&.box {
		align-items: center;
		background-color: use-color('light');
		border: 1px solid use-color('light');
		border-radius: 6px;
		color: use-color('dark');
		display: flex;
		font-size: 12px;
		height: 30px;

		input {
			background: unset;
			border: 0;
			border-radius: 0;
			font-size: 15px;
			padding: 10px 12px;
			text-align: center;
			width: var(--width);

			&::placeholder {
				color: use-color('light-dim');
			}

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				appearance: none;
				margin: 0;
			}

			&[type='number'] {
				appearance: textfield;
			}

			&[disabled] {
				opacity: 0.6;
			}
		}
	}

	.icon {
		position: absolute;
		right: 10px;
		top: 6px;
	}

	&__hint {
		color: use-color('gray');
		display: inline-block;
		font-size: 12px;
		font-weight: 400;
		margin-top: 5px;
		padding-bottom: 10px;
		text-align: left;
	}
}

[disabled],
.disabled {
	opacity: 0.38;
}
