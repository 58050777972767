.preview-canvas {
	background-color: #151515;
	display: flex;
	justify-content: center;
	overflow: hidden;

	&__canvas {
		background-color: black;
	}
}
